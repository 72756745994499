@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('https://restaurant.yemek.club/assets/font/Gilroy-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Black';
  src: url('https://restaurant.yemek.club/assets/font/Gilroy-Black.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('https://restaurant.yemek.club/assets/font/Gilroy-ExtraBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url('https://restaurant.yemek.club/assets/font/Gilroy-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('https://restaurant.yemek.club/assets/font/Gilroy-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('https://restaurant.yemek.club/assets/font/Gilroy-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: url('https://restaurant.yemek.club/assets/font/Gilroy-SemiBold.ttf') format('truetype');
  font-display: swap;
}

.waiting {
  color: black;
}

:root {
  background-color: #f9f9f9;
}
